import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { ReduxStoreState } from '../../../../../store'
import { SagaHelper } from '../../../../../store/sagaHelper'
import { useSelector } from 'react-redux'
import { Form, Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import DealOutlineFormLoan from './DealOutlineFormLoan'
import { DealOutlineLoan } from '../../../../../store/types/fullproject'
import InputAdapter from '../../../../controls/inputs/InputAdapter'
import { ReactSelectAdapter } from '../../../../controls/inputs/ReactSelectAdapter'
import { hideProgress, showErrorNotification, showProgress, showProjectSavedNotifications } from '../../notifications'
import { TextareaWithCounterAdapter } from '../../../../controls/inputs/TextareaWithCounterAdapter'
import { FormApi } from 'final-form'

import { formIsModified, yupValidateObject } from '../../../../../utils'
import * as yup from 'yup'
import { REG_EXP } from '../../../../../utils/constants/regExps'
import createDecorator from 'final-form-focus'
import { USER_FLOW } from '../../../../../utils/constants/projectStepIds'

const focusOnErrors = createDecorator()
type Option = {
    value: string
    label: string
}
const statesList: Array<Option> = [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'DC', label: 'District Of Columbia' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' },
]

const sagaName = 'fullProjectDealOutline'

export function DealOutlineForm(props: any) {
    // hooks
    const [changed, setChanged] = useState(false)
    const [loaded, setLoaded] = useState('form')
    const router = useRouter()
    useEffect(() => {
        setChanged(false)
        SagaHelper.run([sagaName, 'getFullProjectDealOutline'], {
            projectId: router.query.edit,
        })
            .then(() => {
                setLoaded('form-loaded')
            })
            .catch((e) => console.log('error', e))
    }, [router.query.edit])
    const storeData = useSelector((store: ReduxStoreState) => store.fullProject.dealOutline.data)
    const projectStatus = useSelector((store: ReduxStoreState) => store.project.status)

    // data
    const { number, street, city, state, zip, neighborhood } = parseAddress(storeData.address)
    const loans: Array<DealOutlineLoan> = storeData.loans || []
    const { flow } = props?.user
    const fieldsDisabled = projectStatus == 'live' && !props.user.isAdmin && flow == USER_FLOW.COMPARABLES_ONLY

    //methods
    const handleChange = useCallback((fields: any) => {
        fields.address = stringtifyAddress(fields)
        fields.projectId = router.query.edit
        //SagaHelper.run([sagaName, 'setFormData'], fields)
        return null
    }, [])
    const handleSubmit = useCallback(
        async (fields, formApi: FormApi) => {
            try {
                if (fields?.loans?.length == 1) {
                    const loan = fields.loans[0]
                    if (!loan.type && !loan.amount) {
                        fields.loans = []
                    }
                }
                let errors
                if (flow !== USER_FLOW.COMPARABLES_ONLY) {
                    errors = yupValidateObject(
                        yup.object().shape({
                            loans: yup.array().of(
                                yup.object().shape({
                                    type: yup.string().required('Required'),
                                    amount: yup
                                        .mixed()
                                        .test('amount', 'Required', (value) => {
                                            return !!value
                                        })
                                        .test('amount', 'Should be a number', (value) => {
                                            return !value || REG_EXP.NUMERIC.test(value)
                                        }),
                                }),
                            ),
                        }),
                        fields,
                    )
                } else {
                    errors = yupValidateObject(
                        yup.object().shape({
                            state: yup.string().required('Required'),
                            city: yup.string().required('Required'),
                            street: yup.string().required('Required'),
                            number: yup.string().required('Required'),
                            zip: yup.string().required('Required'),
                        }),
                        fields,
                    )
                }
                if (errors) {
                    DealOutlineForm.Promise.reject()
                    return errors
                }
                if (formIsModified(formApi) || changed) {
                    await showProgress()
                    try {
                        fields.address = stringtifyAddress(fields)
                        fields.projectId = router.query.edit
                        await SagaHelper.run([sagaName, 'setFullProjectDealOutline'], fields)
                        DealOutlineForm.Promise.resolve()
                    } catch (e) {
                        await showErrorNotification(e.message)
                        DealOutlineForm.Promise.reject()
                        return
                    } finally {
                        await hideProgress()
                    }
                    await showProjectSavedNotifications()
                } else {
                    DealOutlineForm.Promise.resolve()
                }
            } catch (e) {
                DealOutlineForm.Promise.reject(e)
            }
        },
        [changed],
    )
    const addLoan = useCallback((e: SyntheticEvent) => {
        SagaHelper.run([sagaName, 'addFullProjectLoan'])
        setChanged(true)
    }, [])
    const onLoanDeleted = useCallback(() => {
        setChanged(true)
    }, [])
    return (
        <div className="row justify-content-md-center">
            <div className="col-md-11">
                <Form
                    key={loaded}
                    onSubmit={handleSubmit}
                    decorators={[focusOnErrors]}
                    mutators={{
                        ...arrayMutators,
                    }}
                    validate={handleChange}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {
                        return (
                            <form id="frmDealOutline" onSubmit={handleSubmit}>
                                <div className="form-row">
                                    <div className="col-md-12 ">
                                        <label htmlFor="">Address</label>
                                    </div>

                                    <div className="col-md-6 ">
                                        <div className="form-group">
                                            <Field
                                                component={ReactSelectAdapter}
                                                name="state"
                                                initialValue={state}
                                                options={statesList}
                                                isDisabled={fieldsDisabled}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6 ">
                                        <div className="form-group">
                                            <Field
                                                component={InputAdapter}
                                                name="city"
                                                initialValue={city}
                                                className="form-control"
                                                placeholder={'City'}
                                                disabled={fieldsDisabled}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12 ">
                                        <div className="form-group">
                                            <Field
                                                component={InputAdapter}
                                                name="neighborhood"
                                                initialValue={neighborhood}
                                                className="form-control"
                                                placeholder="Neighborhood/area (when applies)"
                                                disabled={fieldsDisabled}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6 ">
                                        <div className="form-group">
                                            <Field
                                                component={InputAdapter}
                                                name="street"
                                                initialValue={street}
                                                className="form-control"
                                                placeholder="Street"
                                                disabled={fieldsDisabled}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-2 ">
                                        <div className="form-group">
                                            <Field
                                                component={InputAdapter}
                                                name="number"
                                                initialValue={number}
                                                className="form-control"
                                                placeholder="Number"
                                                disabled={fieldsDisabled}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 ">
                                        <div className="form-group">
                                            <Field
                                                component={InputAdapter}
                                                name="zip"
                                                initialValue={zip}
                                                className="form-control"
                                                placeholder="Zip code"
                                                disabled={fieldsDisabled}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {false && flow !== USER_FLOW.COMPARABLES_ONLY && (
                                    <React.Fragment>
                                        <div className="form-row">
                                            <div className="col-md-11 ">
                                                <label htmlFor="">Financing requirements</label>
                                            </div>
                                            <div className="col-md-auto ml-auto ">
                                                <div className="lbl_tool">
                                                    <span
                                                        className="toltip-con"
                                                        data-toggle="tooltip"
                                                        data-placement="right"
                                                        title="Helper text gives context about a field’s input, such as how the input will be used. It should be visible either persistently or only on focus."
                                                    >
                                                        <i className="material-icons-outlined">help_outline</i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div id="repeaterLoan" className="col-md-12 repeater">
                                                <div className="items-container">
                                                    <FieldArray name="loans">
                                                        {({ fields }) => (
                                                            <div>
                                                                {loans.map((loan: DealOutlineLoan, index: number) => {
                                                                    return (
                                                                        <DealOutlineFormLoan
                                                                            key={`${loan.type}${index}`}
                                                                            loans={loans}
                                                                            loan={loan}
                                                                            fields={fields}
                                                                            index={index}
                                                                            onDelete={onLoanDeleted}
                                                                        />
                                                                    )
                                                                })}
                                                            </div>
                                                        )}
                                                    </FieldArray>
                                                </div>
                                                <div className="col-md-12 d-flex flex-row-reverse px-1">
                                                    <a
                                                        type="button"
                                                        className=" btn-dark repeater-add-btn"
                                                        onClick={addLoan}
                                                    >
                                                        <i className="material-icons-outlined">add_circle_outline</i>
                                                        <span>Add another </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-3">
                                            <div className="col-12 " />
                                            <div className="col-md-4">
                                                <div className="lbl">
                                                    <div className="">Show chart on Offer Memorandum</div>
                                                </div>
                                            </div>
                                            <div className="col-md-2 ">
                                                <div className="form-group">
                                                    <Field
                                                        component={'input'}
                                                        name="financingRequirementsChartVisible"
                                                        initialValue={storeData.financingRequirementsChartVisible}
                                                        className="form-control totalsale"
                                                        type={'checkbox'}
                                                        value={'1'}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <Field
                                                        name={`financingRequirementsChartType`}
                                                        component={ReactSelectAdapter}
                                                        placeholder="chart type"
                                                        options={[
                                                            { value: 'pie', label: 'Pie chart' },
                                                            { value: 'bar', label: 'Bar chart' },
                                                        ]}
                                                        initialValue={storeData.financingRequirementsChartType}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="lbl_tool">
                                                    <span
                                                        className="toltip-con"
                                                        data-toggle="tooltip"
                                                        data-placement="right"
                                                        title="Helper text gives context about a field’s input, such as how the input will be used. It should be visible either persistently or only on focus."
                                                    >
                                                        <i className="material-icons-outlined">help_outline</i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-12 " />
                                        </div>
                                    </React.Fragment>
                                )}
                                <div className="form-row">
                                    <div className="col-md-11 ">
                                        <label htmlFor="">Blurb</label>
                                    </div>
                                    <div className="col-md-auto ml-auto ">
                                        <div className="lbl_tool">
                                            <span
                                                className="toltip-con"
                                                data-toggle="tooltip"
                                                data-placement="right"
                                                title="Helper text gives context about a field’s input, such as how the input will be used. It should be visible either persistently or only on focus."
                                            >
                                                <i className="material-icons-outlined">help_outline</i>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-md-12 ">
                                        <Field
                                            component={TextareaWithCounterAdapter}
                                            name={'blurb'}
                                            className="form-control textarea-min-height"
                                            placeholder="Tell us about the project in your own words "
                                            maxLength={1000}
                                            initialValue={storeData.blurb}
                                        />
                                    </div>
                                </div>
                            </form>
                        )
                    }}
                />
            </div>
            {/*language=SCSS*/}
            <style jsx>{`
                .btn-dark {
                    color: #5086fb !important;
                }
                .form-control:disabled,
                .form-control[readonly] {
                    background-color: transparent !important;
                    opacity: 1 !important;
                }
            `}</style>
        </div>
    )
}
DealOutlineForm.Promise = null

export async function dealOutlineSubmit() {
    return new Promise((resolve, reject) => {
        DealOutlineForm.Promise = { resolve, reject }
        const form = document.getElementById('frmDealOutline')
        form.dispatchEvent(new Event('submit', { cancelable: true }))
    })
}
export function parseAddress(address: string): {
    number: string
    street: string
    state: string
    zip: string
    city: string
    neighborhood: string
} {
    const out = {
        number: '',
        street: '',
        state: '',
        zip: '',
        city: '',
        neighborhood: '',
    }
    if (address) {
        const addArr = address.split(',')

        if (typeof addArr[0] !== 'undefined' && addArr[0]?.length > 0) {
            const numberStreet = addArr[0].split(' ')
            let streetText = ''

            numberStreet.forEach((value, index) => {
                if (index == 0) {
                    out.number = value.trim()
                } else {
                    streetText = streetText + ' ' + value
                }
            })
            out['street'] = streetText.trim()
        }
        let city = ''
        let neighborhood = ''
        let stateAndZip = ''
        if (addArr?.length == 3) {
            city = addArr[1]
            stateAndZip = addArr[2]
        }
        if (addArr?.length == 4) {
            neighborhood = addArr[1]
            city = addArr[2]
            stateAndZip = addArr[3]
        }
        out['city'] = city.trim()
        out['neighborhood'] = neighborhood.trim()

        if (stateAndZip) {
            const stateZip = stateAndZip.trim().split(' ')

            stateZip.forEach((value, index) => {
                if (index == 0) {
                    out['state'] = value.trim()
                } else {
                    out['zip'] = value.trim()
                }
            })
        }
    }
    return out
}
function stringtifyAddress(values: Record<string, any>) {
    let state = values.state
    if (state == null) {
        state = ''
    }
    let number = values.number
    if (number == null) {
        number = ''
    }
    let street = values.street
    if (street == null) {
        street = ''
    }
    let neighborhood = values.neighborhood
    if (neighborhood == null) {
        neighborhood = ''
    }
    let city = values.city
    if (city == null) {
        city = ''
    }
    let zip = values.zip
    if (zip == null) {
        zip = ''
    }
    const address =
        number.replace(',', '') +
        ' ' +
        street.replace(',', '') +
        ', ' +
        (neighborhood ? neighborhood.replace(',', '') + ', ' : '') +
        city.replace(',', '') +
        ', ' +
        state +
        ' ' +
        zip.replace(',', '')
    return address
}
