import React, { FunctionComponent } from 'react'
import dynamic from 'next/dynamic'
import { CHART_COLOR } from '../../../../utils/constants/chartColors'
import { MONTHS_SHORT } from '../../../../utils/constants/months'
import numeral from 'numeral'
import { ApexOptions } from 'apexcharts'

const Chart = dynamic(() => import('react-apexcharts'), {
    ssr: false,
})

type Props = {
    city: string
    housePriceChart: Array<{ x: number; y: number }>
}

export const HousingPriceChart: FunctionComponent<Props> = (props: Props) => {
    const { housePriceChart, city } = props
    const data = []
    const labels = []

    for (const item of housePriceChart) {
        data.push(item.y)
        labels.push(item.x)
    }

    const chartTitle = city + ', ' + `Avg Unit Price`

    const chart = {
        series: [
            {
                name: city,
                data,
            },
        ],
        options: {
            chart: {
                height: 350,
                type: 'line',
                id: 'areachart-2',
                toolbar: {
                    show: false,
                },
            },
            stroke: {
                curve: 'smooth',
            },
            colors: CHART_COLOR.BLUE,
            annotations: {
                xaxis: [
                    {
                        x: new Date().getTime(),
                        x2: new Date(labels[labels.length - 1]).getTime(),
                        fillColor: '#B3F7CA',
                        opacity: 0.4,
                        label: {
                            borderColor: '#B3F7CA',
                            style: {
                                fontSize: '14px',
                                color: '#fff',
                                background: '#00E396',
                            },
                            offsetY: 12,
                            offsetX: 34,
                            text: 'Forecast',
                            orientation: 'horizontal',
                        },
                    },
                ],
            },
            dataLabels: {
                enabled: false,
            },
            grid: {
                padding: {
                    right: 30,
                    left: 20,
                },
            },
            title: {
                text: '',
                align: 'left',
                style: {
                    color: '#fff',
                },
            },
            labels,
            xaxis: {
                type: 'datetime',
            },
            yaxis: {
                labels: {
                    formatter: function (item) {
                        return numeral(item).format('$0,0')
                    },
                },
            },
            tooltip: {
                enabled: true,
                theme: 'dark',
                fixed: {
                    enabled: true,
                    position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
                    offsetY: 30,
                    offsetX: 60,
                },
                x: {
                    format: 'MMM / yyyy',
                },
                y: {
                    formatter: function (item) {
                        return numeral(item).format('$0,0')
                    },
                },
            },
        },
    }

    return (
        <div className={'row w-100 my-5'}>
            <div className="col-12">
                <h3>{chartTitle}</h3>
                <Chart options={chart.options as ApexOptions} series={chart.series} type="line" height={450} />
            </div>
        </div>
    )
}
