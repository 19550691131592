import React, { FunctionComponent, useMemo, useState } from 'react'
import numeral from 'numeral'
import { ShowMoreButton } from './ShowMoreButton'
import { useRouter } from 'next/router'

type Props = {
    marketTrends: Array<{
        year: number
        askingRentCity: number
        vacancyCity: number
        askingRentSubmarket: number
        vacancySubmarket: number
    }>
    city: string
    submarket: string
}

export const AskingRentTable: FunctionComponent<Props> = (props: Props) => {
    const { marketTrends, submarket, city } = props

    const initialRows = 10
    const rowsStep = 10
    const [rows, setRows] = useState<number>(initialRows)
    const router = useRouter()
    const { printmode } = useMemo(() => {
        let printmode = false
        if (router.asPath.indexOf('printmode=true') > -1) {
            printmode = true
        }
        return { printmode }
    }, [router])
    return (
        <div
            className="row w-100 my-5 markPdfPageBreakBefore"
            style={
                printmode
                    ? {
                          display: 'block !important',
                          pageBreakBefore: 'always',
                          breakBefore: 'always',
                      }
                    : {}
            }
        >
            <h3>Asking Rent and Vacancy Trends By Year (per Unit)</h3>
            <table className="table table-borderless table-striped table--large table--smallmobile my-4">
                <thead>
                    <tr>
                        <th>Year</th>
                        <th>{city} AskingRent per Unit</th>
                        <th>{city} % Vacant</th>
                        <th>{submarket} AskingRent per Unit</th>
                        <th>{submarket} % Vacant</th>
                    </tr>
                </thead>
                <tbody>
                    {marketTrends?.map((rent, i) => {
                        if (i > rows) {
                            return null
                        }
                        return (
                            <tr key={i}>
                                <td>{rent.year}</td>
                                <td>${rent.askingRentCity}</td>
                                <td>{rent.vacancyCity}</td>
                                <td>${rent.askingRentSubmarket}</td>
                                <td>{rent.vacancySubmarket}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            <ShowMoreButton
                showMore={(e) => {
                    e.preventDefault()
                    setRows((rows) => rows + rowsStep)
                }}
                showLess={(e) => {
                    e.preventDefault()
                    setRows((rows) => rows - rowsStep)
                }}
                showMoreVisible={marketTrends.length > rows}
                showLessVisible={rows > initialRows}
            />

            {/*language=scss*/}
            <style jsx>{`
                tr {
                    transition: all linear 0.2s;
                    cursor: pointer;
                    &:hover {
                        box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.5);
                    }
                }
            `}</style>
        </div>
    )
}
