import React from 'react'
// eslint-disable-next-line @typescript-eslint/naming-convention
import dynamic from 'next/dynamic'
import { ApexOptions } from 'apexcharts'

const Chart = dynamic(() => import('react-apexcharts'), {
    ssr: false,
})
type Props = {
    data: Array<{ year: number; value: number }>
}
export function ConstructionChart(props: Props) {
    if (!props?.data) {
        return null
    }

    let avg = 0
    for (const item of props.data) {
        avg += item.value
    }
    avg = Math.round(avg / props.data.length)

    const series = [
        {
            type: 'bar',
            name: 'Past Construction',
            data: props.data.map((item, ind) => {
                if (ind < props.data.length - 1) {
                    return item.value
                }
                return 0
            }),
        },
        {
            type: 'bar',
            name: 'Current Under Construction',
            data: props.data.map((item, ind) => {
                if (ind == props.data.length - 1) {
                    return item.value
                }
                return 0
            }),
        },
        {
            type: 'line',
            name: 'All-Time Average',
            data: props.data.map((item, ind) => avg),
        },
    ]
    const colors = ['#777777', '#0000ff', '#ffffff']
    const options: ApexOptions = {
        chart: {
            type: 'line',
            toolbar: {
                show: false,
            },
            stacked: true,
        },
        colors: colors,
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '70%',
                distributed: false,
            },
        },
        stroke: {
            width: [0, 0, 4],
            dashArray: [0, 0, 8],
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0,
                    },
                },
            },
        ],
        tooltip: {
            enabled: false,
        },
        xaxis: {
            categories: props.data.map((item) => `${item.year}`),
            labels: {
                style: {
                    colors: colors,
                    fontSize: '12px',
                },
            },
        },
        legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            offsetX: 40,
            labels: {
                colors: ['#fff'],
                // useSeriesColors: true,
            },
        },
        title: {
            text: '',
            align: 'left',
            style: {
                color: '#fff',
            },
        },
    }
    return (
        <div className={'row w-100 my-5'}>
            <div className="col-12">
                <h3>Units Under Construction</h3>
                <Chart series={series} options={options} type="line" height={350} width="100%" />
            </div>
        </div>
    )
}
