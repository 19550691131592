import React, { FunctionComponent, useMemo } from 'react'
import { MapAdapter } from '../../../defaultElements/MapAdapter'
import { Marker } from 'react-google-maps'
import MarkerWithLabel from 'react-google-maps/lib/components/addons/MarkerWithLabel'
import { useRouter } from 'next/router'

type Props = {
    rentComparables: Array<{
        name: string
        address: string
        yearBuilt: number
        units: number
        avgUnitSf: number
        location: {
            lat: number
            lng: number
        }
        rating: string
        priceStudio?: number
        price1Bed?: number
        price2Bed?: number
        price3Bed?: number
        avrRentSF: number
    }>
    location: {
        lat: number
        lng: number
        address: string
    }
}

export const RentComparablesMap: FunctionComponent<Props> = (props: Props) => {
    const { rentComparables, location } = props

    return (
        <div className="row w-100 my-5 markPdfPageBreakBefore">
            <div className="col-12">
                <div className="map-wrapper">
                    <MapAdapter defaultZoom={13} defaultCenter={location} height={700}>
                        <Marker
                            position={location}
                            title={'Current location'}
                            icon={'http://maps.google.com/mapfiles/ms/micons/green-dot.png'}
                        />
                        {rentComparables?.map((comparable, i) => {
                            return (
                                <MarkerWithLabel
                                    key={`${comparable.name}${i}`}
                                    position={comparable.location}
                                    labelAnchor={{ x: 30, y: 0 }}
                                >
                                    <div
                                        style={{
                                            fontSize: '0.65rem',
                                            padding: '3px',
                                            color: '#000',
                                            backgroundColor: '#fff',
                                            maxWidth: 60,
                                            fontWeight: 700,
                                            textAlign: 'center',
                                        }}
                                    >
                                        $
                                        {Math.max(
                                            comparable?.priceStudio || 0,
                                            comparable?.price1Bed || 0,
                                            comparable?.price2Bed || 0,
                                            comparable?.price3Bed || 0,
                                        )}
                                        +/MO
                                    </div>
                                </MarkerWithLabel>
                            )
                        })}
                    </MapAdapter>
                </div>
            </div>
            {/*language=scss*/}
            <style jsx>{`
                .map-wrapper {
                    width: 100%;
                }
            `}</style>
        </div>
    )
}
