import React, { useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'

import { ConstructionChart } from './ConstructionsChart'
import { RentComparablesTable } from './RentComparablesTable'
import { AskingRentTable } from './AskingRentTable'
import { MarketRentChart } from './MarketRentChart'
import { RentComparablesMap } from './RentComparablesMap'
import { parseAddress } from '../../fullproject/forms/DealOutlineForm/DealOutlineForm'
import { HousingPriceChart } from './HousingPriceChart'
import { RentByUnitType } from './RentByUnitType'
import { MarketRentRentByUnitType } from '../../../../generated/graphql'
import { arrayToObject } from '../../../../utils/arrayUtils'
export type RentValue = {
    date: string
    value: number
}
export function Housing(props: any) {
    const router = useRouter()
    const projectId = +router.query.id
    let chartData: {
        marketTrends?: Array<any>
        chart?: Array<any>
        rentComparables?: Array<any>
        submarket?: string
        housePriceChart?: Array<any>
        rentByUnitType: Array<MarketRentRentByUnitType>
    }
    if (props?.content?.data?.length > 0) {
        chartData = arrayToObject(props.content.data)
    }

    const [logo, setLogo] = useState({ alt: '', src: '' })

    useEffect(() => {
        // init google analytics
        if (localStorage.getItem('accountLogoUrl')) {
            setLogo((logo) => ({
                alt: localStorage.getItem('accountName') || '',
                src: '/v1/' + localStorage.getItem('accountLogoUrl') || '',
            }))
        } else {
            setLogo((logo) => ({
                alt: 'Seecares',
                src: '',
            }))
        }
    }, [])

    const address = parseAddress(props?.location?.address)
    const submarket = chartData?.submarket || 'Sub Market'
    const showPriceChart = chartData?.housePriceChart && chartData?.housePriceChart?.length > 0

    const { printmode } = useMemo(() => {
        let printmode = false
        if (router.asPath.indexOf('printmode=true') > -1) {
            printmode = true
        }
        return { printmode }
    }, [router])

    return (
        <>
            <section className="cblock cblock--main" id="intro">
                <div className="container">
                    <div className="row" style={printmode ? { display: 'block' } : {}}>
                        {!props.noTtitle && (
                            <div className="cblock__head">
                                <h1>{props.content?.title}</h1>
                            </div>
                        )}
                        <div className="row">
                            <div
                                className="cblock__body container housing"
                                dangerouslySetInnerHTML={
                                    /* eslint-disable-next-line @typescript-eslint/naming-convention */
                                    { __html: props.content?.content }
                                }
                            />
                        </div>
                        {!props.noShowCharts && showPriceChart && (
                            <HousingPriceChart
                                housePriceChart={chartData?.housePriceChart}
                                city={address?.city || ''}
                            />
                        )}
                        {!props.noShowCharts && chartData?.chart && <ConstructionChart data={chartData?.chart} />}
                        {chartData?.marketTrends && (
                            <MarketRentChart
                                marketTrends={chartData?.marketTrends}
                                city={address?.city || ''}
                                submarket={submarket}
                            />
                        )}
                        {!props.noShowCharts && chartData?.rentByUnitType && (
                            <RentByUnitType data={chartData?.rentByUnitType} city={address?.city || ''} />
                        )}
                        {!props.noShowCharts && chartData?.marketTrends && (
                            <AskingRentTable
                                marketTrends={chartData?.marketTrends}
                                city={address?.city || ''}
                                submarket={submarket}
                            />
                        )}
                        {!props.noShowCharts && chartData?.rentComparables && (
                            <RentComparablesTable
                                rentComparables={chartData.rentComparables}
                                city={address?.city || ''}
                                address={`${address.number || ''} ${address.street || ''}`}
                            />
                        )}
                        {!props.noShowCharts && chartData?.rentComparables && (
                            <RentComparablesMap rentComparables={chartData.rentComparables} location={props.location} />
                        )}
                    </div>
                </div>
            </section>

            {/*language=SCSS*/}
            <style jsx global>{`
                .cblock__body.container.housing img {
                    max-width: 70%;
                    height: auto;
                }
            `}</style>
        </>
    )
}
