import React from 'react'
import { FieldRenderProps } from 'react-final-form'
//@ts-ignore
import TextareaAutosize from 'react-textarea-autosize'

export function TextareaWithCounterAdapter({ input, meta, ...props }: FieldRenderProps<string, HTMLTextAreaElement>) {
    const hasError = meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)
    return (
        <div className="form-group">
            <TextareaAutosize
                {...input}
                {...props}
                className={`${props.className} ${hasError ? 'error' : ''}`}
                minRows={2}
                data-testid={input.name}
            />
            <div className={`words_count text-right ${input.value?.length >= props.maxLength ? 'highlighted' : ''}`}>
                <span className="count">{input.value?.length}</span>/{props.maxLength}
            </div>
            {hasError && (
                <span className={'errorText'}>
                    {meta.error}
                    {meta.submitError}
                </span>
            )}
            {/*language=SCSS*/}
            <style jsx>
                {`
                    .words_count {
                        transition: 0.2s linear all;
                    }
                    .highlighted {
                        font-size: 14px;
                        font-weight: bold;
                    }
                    :global(textarea.form-control) {
                        padding: 2px 12px;
                    }
                    :global(textarea.form-control:focus) {
                        padding: 1px 11px;
                    }

                    :global(.form-control:disabled, .form-control[readonly]) {
                        background-color: transparent !important;
                        opacity: 0.5 !important;
                    }
                    .errorText {
                        color: #cf6679;
                        position: absolute;
                        font-size: 11px;
                    }
                `}
            </style>
        </div>
    )
}
