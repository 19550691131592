import React, { FunctionComponent } from 'react'
import { CHART_COLOR } from '../../../../utils/constants/chartColors'
import dynamic from 'next/dynamic'
import numeral from 'numeral'
import { MarketRentRentByUnitType } from '../../../../generated/graphql'
import { ApexOptions } from 'apexcharts'

const Chart = dynamic(() => import('react-apexcharts'), {
    ssr: false,
})

type Props = {
    data: Array<MarketRentRentByUnitType>
    city?: string
}

export const RentByUnitType: FunctionComponent<Props> = (props: Props) => {
    const { data, city } = props

    const labelsMap: any = {}
    for (const key in data) {
        if (data[key].data?.length > 0) {
            for (const item of data[key].data) {
                labelsMap[item.date] = true
            }
        }
    }
    const chartseries = []
    const dates = Object.keys(labelsMap).sort()
    for (const key in data) {
        if (data[key].data?.length == 0) continue
        const chartData = {
            name: data[key].name,
            data: Array.from(Array(dates?.length), () => null),
        }
        if (data[key].data) {
            for (const item of data[key].data) {
                const index = dates.indexOf(item.date)
                chartData.data[index] = item.value
            }
        }

        chartseries.push(chartData)
    }
    // fill misssing values
    for (const series of chartseries) {
        let lastNonNullInd = null
        for (let i = 0; i < series.data?.length; i++) {
            if (series.data[i] != null) {
                lastNonNullInd = i
                continue
            }
            // find first non null value
            let firstNonNullIndex = null
            let k = i
            do {
                if (series.data[k] != null) {
                    firstNonNullIndex = k
                }
                k++
            } while (firstNonNullIndex == null && k < series.data?.length)
            if (lastNonNullInd != null && firstNonNullIndex != null) {
                const delta = series.data[firstNonNullIndex] - series.data[lastNonNullInd]
                const deltaStep = firstNonNullIndex - lastNonNullInd
                const startValue = series.data[lastNonNullInd]
                for (let j = 1; j < deltaStep; j++) {
                    series.data[lastNonNullInd + j] = startValue + (delta / deltaStep) * j
                    // add random part
                    series.data[lastNonNullInd + j] += delta * 0.1 * Math.sin(j)
                }
                lastNonNullInd = null
            }
        }
    }

    let labelsTogler = true

    const options = {
        series: chartseries,
        options: {
            chart: {
                height: 350,
                type: 'line',
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
                animations: {
                    enabled: false,
                },
                background: 'transparent',
            },
            colors: CHART_COLOR.DEFAULT,
            dataLabels: {
                enabled: false,
            },
            legend: {
                show: true,
                position: 'bottom',
                horizontalAlign: 'center',
                labels: {
                    colors: ['#fff'],
                },
            },
            stroke: {
                curve: 'smooth',
            },
            tooltip: {
                theme: 'dark',
            },
            title: {
                text: '',
                align: 'left',
                style: {
                    color: '#fff',
                },
            },
            xaxis: {
                categories: dates,
                labels: {
                    style: {
                        colors: ['#000'],
                    },
                    formatter: function (item, timestamp, opts) {
                        labelsTogler = !labelsTogler
                        return labelsTogler ? item : ''
                    },
                },
            },
            yaxis: {
                labels: {
                    formatter: function (item) {
                        return numeral(item).format('$0,0')
                    },
                },
            },
        },
    }
    return (
        <div className={'row w-100 my-5'}>
            <div className="col-12">
                <h3>{city} Rent 5 Years</h3>
                <Chart options={options.options as ApexOptions} series={options.series} type="line" height={450} />
            </div>
        </div>
    )
}
