import React, { useCallback, useContext, useMemo } from 'react'
import Select from 'react-select'
import { theme } from '../../../theme'
import { FieldRenderProps } from 'react-final-form'
import { THEME_WHITE, ThemeContext, ThemeContextType } from '../../layouts/ThemeContext'

// @ts-ignore
export function ReactSelectAdapter({ input, meta, ...props }: FieldRenderProps<any, any>) {
    const themeContext = useContext<ThemeContextType>(ThemeContext)
    const fontSize = { fontSize: '12px' }
    const fontColor = {
        color: themeContext?.theme == THEME_WHITE ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.87)',
    }
    const background = {
        background:
            themeContext?.theme == THEME_WHITE
                ? 'white'
                : props.customBackgroundColor
                ? props.customBackgroundColor
                : '#14161B',
    }
    const hasError = meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)

    const borderColor = themeContext?.theme == THEME_WHITE ? 'rgba(0, 0, 0, 0.20)' : 'rgba(255, 255, 255, 0.20)'

    const customStyles = useMemo(
        () => ({
            option: (provided: any, state) =>
                Object.assign(
                    { ...provided },
                    {
                        ...fontColor,
                        ...background,
                        ...fontSize,
                        '&:hover': {
                            backgroundColor: `${themeContext?.theme == THEME_WHITE ? 'rgba(0,0,0,.2)' : '#333333'}`,
                        },
                        fontWeight: state.isSelected ? 'bold' : 'normal',
                    },
                ),
            menu: (provided: any) => ({
                ...provided,
                padding: 0,
                marginLeft: '0px',
                marginRight: '-20px',
                marginTop: 0,
                ...fontSize,
                top: 33,
                left: 0,
                border: `2px solid ${themeContext?.theme == THEME_WHITE ? borderColor : theme.colors.blue}`,
                borderTopWidth: 0,
                zIndex: 100,
            }),
            menuList: (provided: any) => ({
                ...provided,
                padding: 0,
            }),
            placeholder: (provided: any) => {
                return {
                    ...provided,
                    ...fontSize,
                    color: '#495057',
                }
            },
            control: (provided, state) => {
                if (!state.selectProps.menuIsOpen) {
                    return Object.assign(
                        {},
                        {
                            display: 'flex',
                            border: `1px solid ${borderColor}`,
                            borderRadius: '4px',
                            backgroundColor: themeContext?.theme == THEME_WHITE ? 'white' : 'inherit',
                            borderColor: hasError ? theme.colors.red : borderColor,
                            borderWidth: hasError ? '2px' : '1px',
                            height: 40,
                        },
                    )
                } else {
                    return Object.assign(
                        {},
                        {
                            display: 'flex',
                            border: `1px solid ${borderColor}`,
                            borderRadius: '4px 4px 0 0',
                            backgroundColor: 'inherit',
                            borderColor: `${themeContext?.theme == THEME_WHITE ? borderColor : theme.colors.blue}`,
                            borderWidth: '2px',
                            height: 40,
                        },
                    )
                }
            },
            indicatorSeparator: () => ({
                display: 'none',
            }),
            singleValue: (provided: any) => {
                return {
                    ...provided,
                    ...fontSize,
                    ...fontColor,
                }
            },
            container: (provided, state) => {
                return {
                    ...provided,
                    padding: 0,
                    position: 'relative',
                    borderWidth: 0,
                    // border: 0,
                    // border: '1px solid black',
                }
            },
            input: (provided: any) => {
                return {
                    ...provided,
                    ...fontSize,
                    ...fontColor,
                    // border: '1px solid red',
                }
            },
            noOptionsMessage: (provided: any) => {
                return {
                    ...provided,
                    ...fontSize,
                    ...fontColor,
                    background: theme.colors.bgGrey,
                }
            },
        }),
        [hasError],
    )

    const onChange = useCallback((value) => {
        input.onChange(value.value)
    }, [])

    return (
        <div style={props.style || { width: '100%' }} data-testid={input.name}>
            <Select
                {...props}
                instanceId={`select-${input.name}`}
                styles={customStyles}
                onChange={onChange}
                value={props.options.filter((option: any) => option.value === input.value)}
            />
            <input type="text" style={{ width: 0, height: 0, display: 'block', opacity: 0 }} name={input.name} />
            {hasError && (
                <span className={'errorText'}>
                    {meta.error}
                    {meta.submitError}
                </span>
            )}
            <style jsx>{`
                .errorText {
                    color: #cf6679;
                    position: relative;
                    font-size: 11px;
                    top: -10px;
                }
            `}</style>
        </div>
    )
}
