import React, { useMemo, useState } from 'react'
import numeral from 'numeral'
import { ShowMoreButton } from './ShowMoreButton'
import { useRouter } from 'next/router'

type Props = {
    rentComparables: Array<{
        name: string
        address: string
        yearBuilt: number
        units: number
        avgUnitSf: number
        location: {
            lat: number
            lng: number
        }
        rating: string
        price1Bed: number | undefined
        price2Bed: number | undefined
        price3Bed: number | undefined
        priceStudio: number | undefined
        avrRentSF: number
    }>
    address: string
    city: string
}

export function RentComparablesTable(props: Props) {
    const { rentComparables, city, address } = props

    const initialRows = 10
    const rowsStep = 10
    const [rows, setRows] = useState<number>(initialRows)

    return (
        <div className="row w-100 my-5 markPdfPageBreakBefore">
            <h3>Multi Family Submarket</h3>
            <div className="w-100 text-right">
                {address} - View of {city}
            </div>
            <table className="table table-borderless table-striped table--large table--smallmobile my-4">
                <thead>
                    <tr>
                        <th />
                        <th />
                        <th colSpan={2}>Property Size</th>
                        <th colSpan={4}>Asking Rent Per Month Per Unit</th>
                        <th />
                    </tr>
                    <tr>
                        <th>Property Name/Address</th>
                        <th>Yr Built</th>
                        <th>Units</th>
                        <th>Avf Unit SF</th>
                        <th>Studio</th>
                        <th>1 Bed</th>
                        <th>2 Bed</th>
                        <th>3 Bed</th>
                        <th>Rent/SF</th>
                    </tr>
                </thead>
                <tbody>
                    {rentComparables?.map((comp, i) => {
                        if (i > rows) {
                            return null
                        }
                        return (
                            <tr key={i}>
                                <td>{drawCell(comp.name)}</td>
                                <td>{comp.yearBuilt}</td>
                                <td>{comp.units}</td>
                                <td>{drawCell(comp.avgUnitSf)}</td>
                                <td>{drawPrice(comp.priceStudio)}</td>
                                <td>{drawPrice(comp.price1Bed)}</td>
                                <td>{drawPrice(comp.price2Bed)}</td>
                                <td>{drawPrice(comp.price3Bed)}</td>
                                <td>{drawPriceFloat(comp.avrRentSF)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <ShowMoreButton
                showMore={(e) => {
                    e.preventDefault()
                    setRows((rows) => rows + rowsStep)
                }}
                showLess={(e) => {
                    e.preventDefault()
                    setRows((rows) => rows - rowsStep)
                }}
                showMoreVisible={rentComparables.length > rows}
                showLessVisible={rows > initialRows}
            />

            {/*language=scss*/}
            <style jsx>{`
                tr {
                    transition: all linear 0.2s;
                    cursor: pointer;
                    &:hover {
                        box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.5);
                    }
                }
            `}</style>
        </div>
    )
}

const drawCell = (item) => {
    if (item && typeof item == 'number') {
        return numeral(item).format('0,0')
    }
    if (item && typeof item == 'string') {
        return item
    }
    return ''
}

const drawPrice = (item) => {
    if (item) {
        return `$${numeral(item).format('0,0')}`
    }
    return ''
}

const drawPriceFloat = (item) => {
    if (item) {
        return `$${numeral(item).format('0,0.00')}`
    }
    return ''
}
