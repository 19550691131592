import React, { FunctionComponent } from 'react'
import dynamic from 'next/dynamic'
import { CHART_COLOR } from '../../../../utils/constants/chartColors'
import { ApexOptions } from 'apexcharts'
// eslint-disable-next-line @typescript-eslint/naming-convention
const Chart = dynamic(() => import('react-apexcharts'), {
    ssr: false,
})

type Props = {
    marketTrends: Array<{
        year: number
        askingRentCity: number
        vacancyCity: number
        askingRentSubmarket: number
        vacancySubmarket: number
    }>
    city: string
    submarket: string
}

type Series = {
    name: string
    type: string
    data: Array<number>
}

export const MarketRentChart: FunctionComponent<Props> = (props: Props) => {
    const { marketTrends, city, submarket } = props
    const labels: Array<string> = []

    const currentAskingRent: Series = {
        name: `${city} Asking Rent`,
        type: 'column',
        data: [],
    }
    const currentVacancyRate: Series = {
        name: `${city} Vacancy Rate`,
        type: 'line',
        data: [],
    }

    const subMarketAskingRent: Series = {
        name: `${submarket} Asking Rent`,
        type: 'column',
        data: [],
    }
    const subMarketVacancyRate: Series = {
        name: `${submarket} Vacancy Rate`,
        type: 'line',
        data: [],
    }

    const series: Array<Series> = []

    for (const item of marketTrends) {
        labels.push(item?.year?.toString())
        currentAskingRent.data.push(item.askingRentCity)
        currentVacancyRate.data.push(item.vacancyCity)
        subMarketAskingRent.data.push(item.askingRentSubmarket)
        subMarketVacancyRate.data.push(item.vacancySubmarket)
    }

    series.push(currentAskingRent)
    series.push(currentVacancyRate)
    // Some cities don't have submarkets
    if (subMarketAskingRent.data[0] != null) {
        series.push(subMarketAskingRent)
        series.push(subMarketVacancyRate)
    }

    const options = {
        series,
        options: {
            chart: {
                height: 350,
                type: 'line',
                toolbar: {
                    show: false,
                },
            },
            colors: CHART_COLOR.BLUE_WHITE,
            stroke: {
                width: [1, 4, 1, 4],
                curve: 'smooth',
            },
            title: {
                text: '',
                style: {
                    color: '#fff',
                },
            },
            dataLabels: {
                enabled: false,
            },
            labels,
            xaxis: {
                type: 'datetime',
            },
            yaxis: [
                {
                    opposite: true,
                    title: {
                        text: '% Vacant',
                        style: {
                            color: '#ffffff',
                            opacity: 1,
                        },
                    },
                },
                {
                    title: {
                        text: 'Asking Rent (per Unit)',
                        style: {
                            color: '#ffffff',
                            opacity: 1,
                        },
                    },
                },
            ],
            tooltip: {
                enabled: true,
                theme: 'dark',
                fixed: {
                    enabled: true,
                    position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
                    offsetY: 30,
                    offsetX: 60,
                },
            },
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                offsetX: 40,
                labels: {
                    colors: ['#fff'],
                    // useSeriesColors: true,
                },
            },
        },
    }
    return (
        <div className={'row w-100 my-5'}>
            <div className="col-12">
                <h3>Asking and Vacancy Trends By Year (per Unit)</h3>
                <Chart options={options.options as ApexOptions} series={options.series} type="line" height={450} />
            </div>
        </div>
    )
}
